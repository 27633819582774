import * as React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import PageHeader from "../../../components/pageheader"
import GridItem from "../../../components/gridItem"
import { graphql } from "gatsby"

const SustainabilityPage = ({ data }) => (
    <Layout>
        {data?.wpPage?.blocks.map((item, i) => {
            switch (item?.name) {
                case "acf/cover-main":
                    const coverMain = item?.coverMain
                    return (
                        <React.Fragment key={i}>
                            <Seo
                                title={coverMain?.title}
                                description={data?.wpPage?.seo?.metaDesc}
                                date={data?.wpPage?.date}
                                img={
                                    data?.wpPage?.seo?.opengraphImage
                                        ?.mediaItemUrl
                                }
                                imgHeight={
                                    data?.wpPage?.seo?.opengraphImage
                                        ?.mediaDetails?.height
                                }
                                imgWidth={
                                    data?.wpPage?.seo?.opengraphImage
                                        ?.mediaDetails?.width
                                }
                            />
                            <PageHeader
                                title={coverMain?.title}
                                subTitle={coverMain?.text}
                                image={coverMain?.img?.mediaItemUrl}
                                breadcrumbs={[
                                    { label: "About", link: "/about" },
                                    {
                                        label: `${coverMain?.title}`,
                                        link: null,
                                    },
                                ]}
                            />
                        </React.Fragment>
                    )
                case "acf/text-w-col-cards":
                    const textWColCards = item?.textWColCards
                    return (
                        <div key={i} className="mb-4">
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                                {textWColCards?.cards.map((card, cardI) => (
                                    <GridItem
                                        key={cardI}
                                        bgImage={card?.img?.mediaItemUrl}
                                        label={card?.title}
                                        link={card?.link?.url}
                                    />
                                ))}
                            </div>
                        </div>
                    )
            }
        })}
    </Layout>
)

export const query = graphql`
    {
        wpPage(slug: { eq: "sustainability" }) {
            date
            seo {
                title
                metaDesc
                opengraphImage {
                    id
                    mediaItemUrl
                    mediaDetails {
                        height
                        width
                    }
                }
            }
            blocks {
                name
                ... on WpAcfCoverMainBlock {
                    coverMain {
                        img {
                            mediaItemUrl
                        }
                        text
                        title
                    }
                }
                ... on WpAcfTextWColCardsBlock {
                    textWColCards {
                        cards {
                            title
                            link {
                                url
                            }
                            img {
                                mediaItemUrl
                            }
                        }
                    }
                }
            }
        }
    }
`

export default SustainabilityPage
